import { useConfigStore } from '~/store/index'

export default defineNuxtPlugin(() => {
    return {
        provide: {
            getAppInfo: () => {
                const config = useConfigStore()
                return config.currentConfig.app_info
            },
        },
    }
})
