import { default as connexioncYINFoxBWEMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/connexion.vue?macro=true";
import { default as donnees_45personnellesfBiLaKGwx7Meta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/donnees-personnelles.vue?macro=true";
import { default as indexq6A7QyPMVlMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/index.vue?macro=true";
import { default as _91k_93nhP1DKEhJzMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/intentions/[k].vue?macro=true";
import { default as mentions_45legalesIjA3nvYeiOMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mentions-legales.vue?macro=true";
import { default as _91k_93XjtnJHigYyMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions-archivees/[k].vue?macro=true";
import { default as mes_45intentions_45archivees5D0RDmbpx8Meta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions-archivees.vue?macro=true";
import { default as _91k_93RxP0WUrW1IMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions/[k].vue?macro=true";
import { default as mes_45intentionshEnBHNmBU3Meta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions.vue?macro=true";
import { default as _91k_937NsoXTyqVNMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-opportunites/[k].vue?macro=true";
import { default as mes_45opportunitesImF2n4sgbOMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-opportunites.vue?macro=true";
import { default as mesintentionsUF4s9L1H80Meta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mesintentions.vue?macro=true";
import { default as mesintentionsArchiveesLvlyEc6UrOMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mesintentionsArchivees.vue?macro=true";
import { default as mesopportunitesRWpMjRFjJLMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mesopportunites.vue?macro=true";
import { default as _91token_93cBqBEUqnBhMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexpl32wNjbKUMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieC44TCegHzpMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie.vue?macro=true";
import { default as _91k_93GdMgDcp5I7Meta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/opportunites/[k].vue?macro=true";
import { default as indexS9BnPB6LX0Meta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/profil/index.vue?macro=true";
import { default as profilHteFihiJ8QMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/profil.vue?macro=true";
import { default as formulaireTR5cxTydIwMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k]/formulaire.vue?macro=true";
import { default as indexYqQuoXPoOOMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k]/index.vue?macro=true";
import { default as _91k_93o1VztvEQA3Meta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k].vue?macro=true";
import { default as sessionsj7YBelygrKMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/sessions.vue?macro=true";
import { default as _91k_93mEfF9qFqTuMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions-archivees/[k].vue?macro=true";
import { default as toutes_45les_45intentions_45archiveesnOa5Zo5atZMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions-archivees.vue?macro=true";
import { default as _91k_93vzImjbAyopMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions/[k].vue?macro=true";
import { default as toutes_45les_45intentionsgR3iIFyJRIMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions.vue?macro=true";
import { default as _91k_933vY3dRS9xvMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-opportunites/[k].vue?macro=true";
import { default as toutes_45les_45opportuniteslUSWbzGiXsMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-opportunites.vue?macro=true";
import { default as touteslesintentions5cTdOAoMISMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/touteslesintentions.vue?macro=true";
import { default as touteslesopportunites2C1JNp7pCzMeta } from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/touteslesopportunites.vue?macro=true";
export default [
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/connexion.vue").then(m => m.default || m)
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/donnees-personnelles.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "intentions-k",
    path: "/intentions/:k()",
    meta: _91k_93nhP1DKEhJzMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/intentions/[k].vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "mes-intentions-archivees",
    path: "/mes-intentions-archivees",
    meta: mes_45intentions_45archivees5D0RDmbpx8Meta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions-archivees.vue").then(m => m.default || m),
    children: [
  {
    name: "mes-intentions-archivees-k",
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions-archivees/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "mes-intentions",
    path: "/mes-intentions",
    meta: mes_45intentionshEnBHNmBU3Meta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions.vue").then(m => m.default || m),
    children: [
  {
    name: "mes-intentions-k",
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-intentions/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "mes-opportunites",
    path: "/mes-opportunites",
    meta: mes_45opportunitesImF2n4sgbOMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-opportunites.vue").then(m => m.default || m),
    children: [
  {
    name: "mes-opportunites-k",
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mes-opportunites/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "mesintentions",
    path: "/mesintentions",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mesintentions.vue").then(m => m.default || m)
  },
  {
    name: "mesintentionsArchivees",
    path: "/mesintentionsArchivees",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mesintentionsArchivees.vue").then(m => m.default || m)
  },
  {
    name: "mesopportunites",
    path: "/mesopportunites",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/mesopportunites.vue").then(m => m.default || m)
  },
  {
    name: mot_45de_45passe_45oublieC44TCegHzpMeta?.name,
    path: "/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieC44TCegHzpMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie.vue").then(m => m.default || m),
    children: [
  {
    name: "mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93cBqBEUqnBhMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue").then(m => m.default || m)
  },
  {
    name: "mot-de-passe-oublie",
    path: "",
    meta: indexpl32wNjbKUMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "opportunites-k",
    path: "/opportunites/:k()",
    meta: _91k_93GdMgDcp5I7Meta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/opportunites/[k].vue").then(m => m.default || m)
  },
  {
    name: profilHteFihiJ8QMeta?.name,
    path: "/profil",
    meta: profilHteFihiJ8QMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/profil.vue").then(m => m.default || m),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/pages/profil/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "sessions",
    path: "/sessions",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/sessions.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93o1VztvEQA3Meta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "sessions-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "sessions-k",
    path: "",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/sessions/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "toutes-les-intentions-archivees",
    path: "/toutes-les-intentions-archivees",
    meta: toutes_45les_45intentions_45archiveesnOa5Zo5atZMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions-archivees.vue").then(m => m.default || m),
    children: [
  {
    name: "toutes-les-intentions-archivees-k",
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions-archivees/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "toutes-les-intentions",
    path: "/toutes-les-intentions",
    meta: toutes_45les_45intentionsgR3iIFyJRIMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions.vue").then(m => m.default || m),
    children: [
  {
    name: "toutes-les-intentions-k",
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-intentions/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "toutes-les-opportunites",
    path: "/toutes-les-opportunites",
    meta: toutes_45les_45opportuniteslUSWbzGiXsMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-opportunites.vue").then(m => m.default || m),
    children: [
  {
    name: "toutes-les-opportunites-k",
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/toutes-les-opportunites/[k].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "touteslesintentions",
    path: "/touteslesintentions",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/touteslesintentions.vue").then(m => m.default || m)
  },
  {
    name: "touteslesopportunites",
    path: "/touteslesopportunites",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_VOIRIE/pages/touteslesopportunites.vue").then(m => m.default || m)
  }
]