import revive_payload_client_vIO3eTWw9D from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_ioredis@_75y5mpmcf3tbfhl7lkewdxvpvq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_11ltUvLO3x from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_ioredis@_75y5mpmcf3tbfhl7lkewdxvpvq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9ZMwxXeoXs from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_ioredis@_75y5mpmcf3tbfhl7lkewdxvpvq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T0segCNKRh from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_ioredis@_75y5mpmcf3tbfhl7lkewdxvpvq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cRui8ElXkG from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_ioredis@_75y5mpmcf3tbfhl7lkewdxvpvq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XTUMCv61Iz from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_ioredis@_75y5mpmcf3tbfhl7lkewdxvpvq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_L1rmjcxo1U from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_ioredis@_75y5mpmcf3tbfhl7lkewdxvpvq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8n8Uy1hOGp from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.32_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/.nuxt/components.plugin.mjs";
import prefetch_client_SHKyrom6q2 from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.41_encoding@0.1.13_eslint@8.57.0_ioredis@_75y5mpmcf3tbfhl7lkewdxvpvq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_q8yHyLyWjb from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.1_vue@3.4.32_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import mergeObjects_JbHzNSpMmN from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/plugins/mergeObjects.ts";
import vuetify_DPVY7aMxlt from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/NUXT_COMMON/plugins/vuetify.ts";
import notifier_TfOkCLHRTj from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/notifier.ts";
import api_client_t0dWRHSNDZ from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/api.client.ts";
import currentPath_yqNfcRq2j9 from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/currentPath.ts";
import currentUser_28J1aqNENu from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/currentUser.ts";
import translation_NgY8VMsLMG from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/translation.ts";
import getColor_Ghj8WPsFjF from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/getColor.ts";
import helper_Bdxgb2x3zG from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/helper.ts";
import formatDate_za3K2nEobv from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/packages/datakode/nuxt-datakode-utils/plugins/formatDate.ts";
import getAppInfo_aqy48HiWla from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/plugins/getAppInfo.ts";
import hasAccess_5YDdY3srs4 from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/plugins/hasAccess.ts";
import leaflet_client_7dEOJA4hbk from "/builds/datakode/pildom/APPS/NUXT_VOIRIE/plugins/leaflet.client.ts";
export default [
  revive_payload_client_vIO3eTWw9D,
  unhead_11ltUvLO3x,
  router_9ZMwxXeoXs,
  payload_client_T0segCNKRh,
  navigation_repaint_client_cRui8ElXkG,
  check_outdated_build_client_XTUMCv61Iz,
  chunk_reload_client_L1rmjcxo1U,
  plugin_vue3_8n8Uy1hOGp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_SHKyrom6q2,
  i18n_q8yHyLyWjb,
  mergeObjects_JbHzNSpMmN,
  vuetify_DPVY7aMxlt,
  notifier_TfOkCLHRTj,
  api_client_t0dWRHSNDZ,
  currentPath_yqNfcRq2j9,
  currentUser_28J1aqNENu,
  translation_NgY8VMsLMG,
  getColor_Ghj8WPsFjF,
  helper_Bdxgb2x3zG,
  formatDate_za3K2nEobv,
  getAppInfo_aqy48HiWla,
  hasAccess_5YDdY3srs4,
  leaflet_client_7dEOJA4hbk
]