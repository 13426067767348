import { useUserStore } from '~/store'

export default defineNuxtPlugin(() => {
    return {
        provide: {
            hasAccess: (appSlug: string) => {
                const userStore = useUserStore()
                const user = userStore.currentUser
                if (!user.k) {
                    return null
                }

                const access = user.application_roles.find(app => app.application.slug === appSlug)
                // return user.role === 'admin' ? 'manage' : access ? access.role : null
                return access ? access.role : null
            },
            hasRequestedApp: (appSlug: string) => {
                const userStore = useUserStore()
                const user = userStore.currentUser
                if (!user.k) {
                    return null
                }

                const access = user.new_access_requests.find(request => request.application.slug === appSlug)
                return !!access
            },
            hasOrganization: (organizationId: string) => {
                const userStore = useUserStore()
                const user = userStore.currentUser
                if (!user.k) {
                    return null
                }

                return user.organization.id === organizationId
            },
            hasManagedOrganization: (organizationId: string) => {
                const userStore = useUserStore()
                const user = userStore.currentUser
                if (!user.k) {
                    return null
                }

                const access = user.managed_organizations.find(managed_organization => managed_organization.id === organizationId)
                return !!access
            },
        },
    }
})
