export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.provide('customMerge', (obj1:object, obj2:object) => {
        const merged = { ...obj1 }
        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                merged[key] = obj1[key] && obj1[key].toString() === '[object Object]'
                    ? customMerge(obj1[key], obj2[key])
                    : obj2[key]
            }
        }
        return merged
    })
})
